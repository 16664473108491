import { formatKeyValueObject, formatKeyValueArray } from '@/utils/base';
import { TaskTypeTextOptions } from '@/constant/task';

export const PRINT = {
  PICKING: 'PICKING',
  PART_PICKING: 'PART_PICKING',
};
export const TASK_TYPE = formatKeyValueArray(TaskTypeTextOptions);
export const TASK_TYPE_ENUM = formatKeyValueObject(TASK_TYPE);
export const TASK_STATUS = [
  { value: 'PREPARE', label: '准备中' },
  { value: 'DOING', label: '作业中' },
  { value: 'FINISHED', label: '已完结' },
  { value: 'CLOSE', label: '已关闭' },
];
export const TASK_STATUS_ENUM = formatKeyValueObject(TASK_STATUS);
export const TASK_STATUS_ENUM_VALUE = formatKeyValueObject(TASK_STATUS, { label: 'value' });
export const SUBTASK_STATUS = [
  { value: 'PREPARE', label: '准备中' },
  { value: 'DOING', label: '作业中' },
  { value: 'FINISHED', label: '已完结' },
  { value: 'CLOSE', label: '已关闭' },
];
export const SUBTASK_STATUS_ENUM = formatKeyValueObject(SUBTASK_STATUS);
export const TASK_STATUS_VALUE_ENUM = formatKeyValueObject(TASK_STATUS, { label: 'value' });
export const BIZ_TYPE = [
  { value: 'STORE', label: '入库' },
  { value: 'MOVE', label: '移动' },
  { value: 'COUNT', label: '盘点' },
  { value: 'WAVE', label: '波次' },
  { value: 'TRANSFER', label: '移库移位' },
];
export const BIZ_TYPE_ENUM = formatKeyValueObject(BIZ_TYPE);
export const BIZ_TYPE_VALUE_ENUM = formatKeyValueObject(BIZ_TYPE, { label: 'value' });
export const OPERATION_TYPE = [
  { value: 'PDA', label: 'PDA' },
  { value: 'PAPER', label: '纸单' },
  { value: 'AUTOMATIC', label: '自动化' },
];
export const OPERATION_TYPE_ENUM = formatKeyValueObject(OPERATION_TYPE);
export const OPERATION_TYPE_ENUM_VALUE = formatKeyValueObject(OPERATION_TYPE, { label: 'value' });
export const IMPORTANT = [
  { value: 'NORMAL', label: '普通' },
  { value: 'URGENT', label: '紧急' },
];
export const IMPORTANT_ENUM = formatKeyValueObject(IMPORTANT);
export const IMPORTANT_ENUM_VALUE = formatKeyValueObject(IMPORTANT, { label: 'value' });
export const STAFF_TYPE = [
  { value: 'REGULAR', label: '正式工' },
  { value: 'TEMPORARY', label: '临时工' },
];
export const STAFF_TYPE_ENUM = formatKeyValueObject(STAFF_TYPE);

// 温层
export const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};

export const ASSIGN_STATUS = [
  { value: undefined, label: '全部' },
  { value: 'ASSIGNED', label: '已分配' },
  { value: 'UN_ASSIGN', label: '未分配' },
];
