import { FormType } from '@/constant/form';
import { YMDHM } from '@/constant/timeFormat';
import { TASK_TYPE } from '@/views/task-center/task-management/constant';
import { DateTime } from '@/constant/tableConfig';

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}

export const TABLECOLUMN = (zoneTemperature) => [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '员工编号',
  prop: 'staffCode',
  minWidth: 120,
}, {
  label: '员工名称',
  prop: 'staffName',
  minWidth: 120,
}, {
  label: '日期',
  prop: 'statisticalDate',
  ...DateTime,
}, {
  label: '作业类型',
  prop: 'taskType',
  minWidth: 100,
  formatter: (row) => filterLabel(row.taskType, TASK_TYPE),
}, {
  label: '温层',
  prop: 'temperature',
  minWidth: 80,
  formatter: (row) => filterLabel(row.temperature, zoneTemperature),
}, {
  label: '作业量',
  prop: 'bigUnitAmount',
  minWidth: 120,
  formatter: ({ bigUnitAmount, smallUnitAmount }) => `${bigUnitAmount}整${smallUnitAmount}零`,
}, {
  label: '作业时长(小时)',
  prop: 'totalHours',
  minWidth: 120,
}, {
  label: '平均时效(/小时)',
  prop: 'bigUnitAmountHoursEfficiency',
  minWidth: 120,
  formatter: ({ bigUnitAmountHoursEfficiency, smallUnitAmountHoursEfficiency }) => `${bigUnitAmountHoursEfficiency}整${smallUnitAmountHoursEfficiency}零`,
}];

export const serchFields = (zoneTemperature) => [
  {
    label: '作业日期',
    prop: 'statisticalSDate',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      format: YMDHM,
    },
  }, {
    label: '员工编号/名称',
    prop: 'staffCode',
    component: FormType.INPUT,
  }, {
    label: '作业类型',
    prop: 'taskType',
    component: FormType.SELECT,
    options: TASK_TYPE,
  },
  {
    label: '温层',
    prop: 'temperature',
    component: FormType.SELECT,
    options: zoneTemperature,
  },
];
