<template>
  <div>
    <pl-block>
      <PlForm
        v-model="searchQuery"
        mode="search"
        :fields="serchFields(zoneTemperature)"
        :form-props="{labelWidth:'120px'}"
        @submit="handleQuery"
        @reset="handleQuery"
      />
    </pl-block>

    <pl-block>
      <nh-pro-table-header>
        <template #headerRight>
          <nh-button
            type="grey"
            size="small"
            plain
            :loading="loading.handleExport"
            :track="{
              trackName: '报表中心/员工作业效率/[导出]'
            }"
            @click="handleExport"
          >
            导出
          </nh-button>
        </template>
      </nh-pro-table-header>
      <PlTable
        v-model:pagination="pagination"
        :loading="loading.getTableData"
        :data="tableData"
        :columns="TABLECOLUMN(zoneTemperature)"
        @update:pagination="handlePaginationChange"
      />
    </pl-block>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import {
  staffWorkEfficiencyReportQuery,
  staffWorkEfficiencyReportExport,
} from './api';

export default {
  name: 'InWarehouseDetails',
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      tableData: [],
      searchQuery: {
        statisticalSDate: [],
        staffCode: null,
        taskType: null,
        temperature: null,
      },
      selectIds: [],
      loading: {
        getTableData: false,
        handleExport: false,
      },
    };
  },
  computed: {
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.tableData = [];
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await staffWorkEfficiencyReportQuery(pagination, params);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    handlePaginationChange() {
      this.getTableData();
    },
    buildParams() {
      const [startStatisticalSDate, endStatisticalSDate] = this.searchQuery.statisticalSDate || [];
      return {
        ...this.searchQuery,
        startStatisticalSDate,
        endStatisticalSDate,
      };
    },
    async handleExport() {
      const data = this.buildParams();
      await staffWorkEfficiencyReportExport(data);
    },
  },
};
</script>
