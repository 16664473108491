import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:员工效率分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17565
 */
export function staffWorkEfficiencyReportQuery(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/staff_work_efficiency_report/page', data, {
    params,
  });
}

/**
 * @description:导出员工效率
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17579
 */
export function staffWorkEfficiencyReportExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/staff_work_efficiency_report/export', data, { fileName: '员工作业效率', extension: 'xlsx' });
}
